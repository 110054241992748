import { addMonths, startOfYear, format } from 'date-fns';
import { pt } from 'date-fns/locale';

import capitalize from './capitalizeWords';

/**
 * Retorna um array com os dias do mês
 */
export function dayOptions() {
  let count = 1;

  const result = [];

  while (count <= 31) {
    result.push({
      label: count,
      value: count,
    });

    count++;
  }

  return result;
}

export function monthsOfYears(justMonth) {
  let count = 1;

  const result = [];

  const year = startOfYear(new Date());
  year.setFullYear(year.getFullYear() - 1);

  while (count <= 36) {

    const month = format(addMonths(year, count - 1), 'MMMM', {
      locale: pt,
    });
    const currentYear = addMonths(year, count - 1).getFullYear();
    if (justMonth) {
      result.push({
        value: count,
        month: capitalize(month),
      });
    } else {
      result.push({
        value: count,
        label: `${capitalize(month)} / ${currentYear}`,
      });
    }

    count++;
  }
  return result;
}

export function monthsOfYear(justMonth) {
  let count = 1;

  const result = [];

  const year = startOfYear(new Date());

  while (count <= 12) {
    const month = format(addMonths(year, count - 1), 'MMMM', {
      locale: pt,
    });

    if (justMonth) {
      result.push({

        month: capitalize(month),
      });
    } else {
      result.push({
        value: count,
        label: capitalize(month),
      });
    }

    count++;
  }
  return result;
}
